/*eslint no-undef: "off"*/
import React, {useContext, useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {
  AuthorizationNotifier,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  LocalStorageBackend,
  RedirectRequestHandler,
  TokenRequest
} from '@openid/appauth';
import {NoHashQueryStringUtils} from '../utils/noHashQueryStringUtils';
import {isBrowser, logout} from '../utils/auth';
import LoadingPage from '../components/LoadingPage';
import {MessageContext} from '../utils/context';

const AuthCallback = (props) => {
  const messageHandler = useContext(MessageContext);
  const [code, setCode] = useState(null);
  const productNr = (isBrowser()) ? localStorage.getItem('productNr') : null;

  useEffect(() => {
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
    const authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      props.location,
      new DefaultCrypto()
    );
    const notifier = new AuthorizationNotifier();
    authorizationHandler.setAuthorizationNotifier(notifier);
    let tokenRequest;
    notifier.setAuthorizationListener((
      request,
      response,
      error) => {
      if (response) {
        let extras = null;

        if (request && request.internal) {
          extras = {};
          extras.code_verifier = request.internal.code_verifier;
          extras.client_secret = process.env.TENDUKE_CLIENT_SECRET;
        }

        tokenRequest = new TokenRequest({
          client_id: process.env.TENDUKE_CLIENT_ID,
          redirect_uri: process.env.TENDUKE_REDIRECT_URI,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: response.code,
          refresh_token: undefined,
          extras
        });
      }

      if(error) {
        messageHandler.createMessage('Something went wrong during login. Try again!', 'error');
        logout();
      }

      const serviceConfig = new AuthorizationServiceConfiguration({token_endpoint: process.env.TENDUKE_TOKEN_ENDPOINT});

      tokenHandler.performTokenRequest(serviceConfig, tokenRequest)
        .then((res) => {
          localStorage.setItem('access_token', res.accessToken);
          localStorage.setItem('id_token', res.idToken);
          window.history.replaceState({}, null, '/');

          // if authentication started by clicking product card vs top corner log in button
          messageHandler.createMessage('Login successful', 'success');
          (productNr) ? navigate('/partnerselection') : navigate('/');
        })
        .catch(() => {
          messageHandler.createMessage('Something went wrong during login. Try again!', 'error');
          logout();
        });
    });

    const params = new URLSearchParams(props.location.search);
    setCode(params.get('code'));

    if (!code) {
      return;
    }

    authorizationHandler.completeAuthorizationRequestIfPossible();
  }, [code, props]);

  return (
    <div style={{'minHeight': '100vh', 'background': 'linear-gradient(180deg, #017EFD 0%, #0174E9 100%)'}}>
      { code ? <LoadingPage /> : null }
    </div>
  );
};

export default AuthCallback;
